<template>
  <ValidationObserver ref="form" v-slot="{ failed }" tag="form">
    <app-dialog
      v-if="!creditorPermissions.directPayment"
      v-model="open"
      title="vouchers.directPayment.noPermissionTitle"
      secondary-action-text="navigation.ok"
    >
      <p>{{ $t('vouchers.directPayment.noPermissionText') }}</p>
    </app-dialog>
    <app-dialog
      v-if="creditorPermissions.directPayment"
      v-model="open"
      :primary-action-text="getPrimaryActionText('vouchers.directPayment.confirmPayment')"
      secondary-action-text="navigation.cancel"
      title="vouchers.directPayment.checkPayment"
      text="vouchers.directPayment.info"
      :disabled="!amount || !voucherDate || failed"
      :loading="loading"
      @submit="submitDirectPayment"
    >
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
        <slot :name="name" v-bind="slotData"></slot>
      </template>

      <v-container class="px-0">
        <v-row dense align="center" class="mt-4 semi-bold">
          <v-col cols="6" class="semi-bold">{{ voucher.numberToShow }}:</v-col>
          <v-col cols="6" class="text-right">
            {{ voucher.leftoverAmount | formatCurrency }}
          </v-col>
        </v-row>
        <v-divider class="mb-5" />

        <app-input-row label="amount">
          <app-number-field
            v-model="amount"
            name="amount"
            :rules="`required|max_currency:${voucher.leftoverAmount}`"
            prepend-inner-icon="mdi-currency-eur"
            placeholder="0,00"
            align-right
          />

          <template #append>
            <app-link-btn
              title="vouchers.takeLeftoverAmount"
              variant="muted"
              @click="amount = voucher.leftoverAmount"
            />
          </template>
        </app-input-row>

        <app-input-row label="date">
          <app-date-picker
            v-model="voucherDate"
            name="vouchers.voucherDate"
            placeholder="dateHint"
            required
            prepend-inner-icon
            align-right
            :max="latestPossibleDate"
          />
        </app-input-row>

        <v-divider class="mt-5" />
        <v-row class="semi-bold" dense justify="space-between">
          <v-col cols="6">{{ $t('vouchers.abandonment.newLeftover') }}:</v-col>
          <v-col class="text-right">
            {{ (voucher.leftoverAmount - amount) | formatCurrency }}
          </v-col>
        </v-row>
      </v-container>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.toVoucher"
      title="vouchers.directPayment.confirmedPayment"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    >
      <p>{{ $t('vouchers.directPayment.paymentReceived') }}</p>
    </app-dialog>
  </ValidationObserver>
</template>

<script>
import { NAMESPACE } from '@/modules/Vouchers/store';
import { NAMESPACE as USER_NAMESPACE } from '@/modules/User/store';
import { mapActions, mapState } from 'vuex';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import WorkQueueFollowUpMixin from '@/mixins/WorkQueueFollowUpMixin';
import { formatDateToTimestamp, formatTimestampToDate } from '@/helper/filter/formatDate';
import { getMilliSecondsStartToday } from '@/statics/timePeriods';

export default {
  name: 'DirectPaymentDialog',

  mixins: [ShowDialogMixin, WorkQueueFollowUpMixin],

  props: {
    voucher: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    amount: null,
    voucherDate: ''
  }),

  computed: {
    ...mapState(USER_NAMESPACE, ['creditorPermissions']),
    voucherIdentifier() {
      return this.$route.params.id;
    },
    latestPossibleDate() {
      return formatTimestampToDate(getMilliSecondsStartToday() / 1000);
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['uploadDirectPayment']),

    async submitDirectPayment() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      const data = {
        amount: this.amount,
        voucherDate: formatDateToTimestamp(this.voucherDate)
      };

      if (this.emitData) {
        this.$emit('submit', data);
        return;
      }

      this.loading = true;
      const payload = {
        voucherIdentifier: this.voucherIdentifier,
        data
      };
      const { error } = await this.uploadDirectPayment(payload);
      this.loading = false;

      if (error) {
        return;
      }

      this.submitted = true;
      this.amount = null;
      this.voucherDate = '';
      this.$refs.form?.reset();
    }
  },

  created() {
    if (this.prefillData) {
      this.amount = this.prefillData.amount;
      this.voucherDate = formatTimestampToDate(this.prefillData.voucherDate);
    }
  }
};
</script>

import { MILLISECONDS_PER_DAY } from '@/statics/timePeriods';

export default {
  methods: {
    calculateDaysOverdue(date) {
      if (date === 0) {
        return 0;
      }
      const timestampStartToday = Number(new Date().setHours(0, 0, 0, 0));
      const millisecondsOverdue = timestampStartToday - date;
      return Math.ceil(millisecondsOverdue / MILLISECONDS_PER_DAY);
    },

    getCountedDueDays(voucher) {
      return this.calculateDaysOverdue(voucher.paymentDeadline) > 0
        ? this.$tc(
            'vouchers.dueDaysCountedPast',
            Math.abs(this.calculateDaysOverdue(voucher.paymentDeadline))
          )
        : this.$tc(
            'vouchers.dueDaysCountedFuture',
            Math.abs(this.calculateDaysOverdue(voucher.paymentDeadline))
          );
    }
  }
};

<template>
  <ValidationObserver ref="form" v-slot="{ failed }" tag="form">
    <app-dialog
      v-model="open"
      :primary-action-text="getPrimaryActionText('vouchers.abandonment.confirmAbandonment')"
      secondary-action-text="navigation.cancel"
      title="vouchers.abandon"
      :text="
        totalLeftoverOnly ? 'vouchers.abandonment.infoTotalLeftover' : 'vouchers.abandonment.info'
      "
      :disabled="failed || !amount"
      :loading="loading"
      @submit="initiateInvoiceAbandonment"
    >
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
        <slot :name="name" v-bind="slotData"></slot>
      </template>

      <v-container class="px-0">
        <v-row dense align="center" class="mt-4 semi-bold">
          <v-col cols="6" class="semi-bold">{{ voucher.numberToShow }}:</v-col>
          <v-col cols="6" class="text-right">
            {{ voucher.leftoverAmount | formatCurrency }}
          </v-col>
        </v-row>
        <v-divider class="mb-5" />

        <app-input-row v-if="!totalLeftoverOnly" label="amount">
          <app-number-field
            v-model="amount"
            name="vouchers.voucherTypes.abandonment"
            :rules="`required|max_currency:${voucher.leftoverAmount}`"
            prepend-inner-icon="mdi-currency-eur"
            placeholder="0,00"
            align-right
          />

          <template #append>
            <app-link-btn
              title="vouchers.takeLeftoverAmount"
              variant="muted"
              @click="amount = voucher.leftoverAmount"
            />
          </template>
        </app-input-row>

        <app-input-row v-else no-indent class="my-4" label="amount">
          {{ voucher.leftoverAmount | formatCurrency }}
        </app-input-row>

        <v-divider class="mt-5" />
        <v-row class="semi-bold" dense justify="space-between">
          <v-col cols="6">{{ $t('vouchers.abandonment.newLeftover') }}:</v-col>
          <v-col class="text-right">
            {{ (voucher.leftoverAmount - amount) | formatCurrency }}
          </v-col>
        </v-row>
      </v-container>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.toVoucher"
      title="vouchers.abandonment.confirmedAbandonment"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    >
      <p>{{ $t('vouchers.abandonment.abandonmentReceived') }}</p>
    </app-dialog>
  </ValidationObserver>
</template>

<script>
import { NAMESPACE } from '@/modules/Vouchers/store';
import { mapActions } from 'vuex';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import WorkQueueFollowUpMixin from '@/mixins/WorkQueueFollowUpMixin';

export default {
  name: 'AbandonmentDialog',

  mixins: [ShowDialogMixin, WorkQueueFollowUpMixin],

  props: {
    totalLeftoverOnly: {
      type: Boolean,
      default: false
    },
    voucher: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    amount: null
  }),

  computed: {
    voucherIdentifier() {
      return this.$route.params.id;
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['executeAbandonment']),

    async initiateInvoiceAbandonment() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      const data = {
        amount: this.amount
      };

      if (this.emitData) {
        this.$emit('submit', data);
        return;
      }

      this.loading = true;
      const { error } = await this.executeAbandonment({
        voucherIdentifier: this.voucherIdentifier,
        data
      });
      this.loading = false;

      if (error) {
        return;
      }

      this.submitted = true;
      this.amount = null;
      this.$refs.form?.reset();
    }
  },

  created() {
    if (this.prefillData && !this.totalLeftoverOnly) {
      this.amount = this.prefillData.amount;
    }

    if (this.totalLeftoverOnly) {
      this.amount = this.voucher.leftoverAmount;
    }
  }
};
</script>

export default {
  props: {
    emitData: {
      type: Boolean,
      default: false
    },
    prefillData: {
      type: [FormData, Object],
      default: null
    },
    primaryActionText: {
      type: String,
      default: ''
    }
  },

  methods: {
    getPrimaryActionText(defaultText) {
      return this.primaryActionText
        ? this.$t(this.primaryActionText)
        : this.isMobile
        ? 'navigation.confirm'
        : defaultText;
    }
  }
};

<template>
  <ValidationObserver ref="form" tag="form">
    <app-dialog
      v-model="open"
      :primary-action-text="getPrimaryActionText('vouchers.invoiceAnnulation.confirmAnnulation')"
      secondary-action-text="navigation.cancel"
      title="vouchers.executeInvoiceAnnulation"
      text="vouchers.invoiceAnnulation.info"
      :disabled="!confirmAnnulation"
      :loading="loading"
      @submit="initiateInvoiceAnnulation"
      @close="confirmAnnulation = false"
    >
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
        <slot :name="name" v-bind="slotData"></slot>
      </template>

      <v-container class="px-0">
        <app-input-row>
          <app-checkbox
            v-model="confirmAnnulation"
            label="vouchers.invoiceAnnulation.approveAnnulation"
            hide-details
          />
        </app-input-row>
      </v-container>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.toVoucher"
      title="vouchers.invoiceAnnulation.confirmedAnnulation"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    >
      <p>{{ $t('vouchers.invoiceAnnulation.annulationReceived') }}</p>
    </app-dialog>
  </ValidationObserver>
</template>

<script>
import { NAMESPACE } from '@/modules/Vouchers/store';
import { mapActions } from 'vuex';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import WorkQueueFollowUpMixin from '@/mixins/WorkQueueFollowUpMixin';

export default {
  name: 'AnnulationDialog',

  mixins: [ShowDialogMixin, WorkQueueFollowUpMixin],

  props: {
    prefillData: {
      type: Object,
      default: undefined
    }
  },

  data: () => ({
    confirmAnnulation: false
  }),

  computed: {
    voucherIdentifier() {
      return this.$route.params.id;
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['executeInvoiceAnnulation']),

    async initiateInvoiceAnnulation() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      if (this.emitData) {
        this.$emit('submit', null);
        return;
      }

      this.loading = true;
      const { error } = await this.executeInvoiceAnnulation(this.voucherIdentifier);
      this.loading = false;

      if (error) {
        return;
      }

      this.submitted = true;
      this.confirmAnnulation = false;
      this.$refs.form?.reset();
    }
  },

  created() {
    if (this.prefillData === null) {
      this.confirmAnnulation = true;
    }
  }
};
</script>
